<template>

  <div class="applyHouseCollection">
    <rxNavBar title="收房申请"></rxNavBar>
    <div class="applyTime">申请时间：&nbsp;{{ dataList.addTime }}</div>
    <div class="headerBlock">
      <div class="wait-approve">{{ dataList.approvalStatusStr }}</div>
      <div class="houseAddress">{{ dataList.roomDetailedAddress }}</div>
      <div class="keyAddress">
        {{ dataList.cityProper }}
        <span class="content-divide-small">|</span>
        {{ dataList.businessCircleName }}
        <span class="content-divide-small">|</span>
        {{ dataList.estateName }}
      </div>
    </div>
    <div class="displayBlock" v-if="imgList.length>0">
      <div class="swiper_show" ref="wrapperBox">
        <div class="content">
          <div class="imgWrap" v-for="(url,i) in imgList" @click="previewImg(i)">
            <van-image style="border-radius: 100px"
                       width="100"
                       height="85"
                       fit="contain"
                       :src="url">
            </van-image>
            <div class="num">
              {{ imgList.length }}图
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="displayBlock">
      <table class="houseInfo">
        <tr>
          <td>
            <div class="boldFont">面积：<span
                class="normalFont">{{ dataList.builtUpArea ? dataList.builtUpArea + '㎡' : '暂无' }}</span></div>
          </td>
          <td>
            <div class="boldFont">改造风格：<span class="normalFont">{{ dataList.decorationStyle }}</span></div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="boldFont">楼层：<span class="normalFont">{{ dataList.floor }}/{{ dataList.totalFloor }}楼</span>
            </div>
          </td>
          <td>
            <div class="boldFont">装修期：<span
                class="normalFont">{{ dataList.renovationTime ? dataList.renovationTime + '天' : '暂无' }}</span></div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="boldFont">朝向：<span class="normalFont">{{ dataList.orientation }}</span></div>
          </td>
          <td>
            <div class="boldFont">租金：<span
                class="normalFont">{{ dataList.rentMoney ? dataList.rentMoney + '元/月' : '暂无' }}</span></div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="boldFont">户型改造：<span
                class="normalFont">{{ dataList.bedroomCount }}+{{ dataList.changHouseNum }}</span></div>
          </td>
          <td>
            <div class="boldFont">总免租期：<span
                class="normalFont">{{ dataList.noFeeTime ? dataList.noFeeTime + '天' : '暂无' }}</span></div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="boldFont">装修预算：<span
                class="normalFont">{{ dataList.decorationMoney ? dataList.decorationMoney+'元' : '暂无' }}</span></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="displayBlock">
      <div class="boldFont descTitle">收房描述:</div>
      <div class="normalFont descText">
        {{ dataList.remarks?dataList.remarks:'暂无' }}
      </div>
    </div>
    <div class="normalBlock">
      <span class="maker" :class="{'orangeColor':(isPass||isReject)}">• </span>
      <span class="blockTitle">审核结果</span>
      <div class="rightText">
        <span class="passText" :class="{'choosedStyle':isPass}" @click="choosePass">通过</span>
        <span class="content-divide"> | </span>
        <span class="rejectText" :class="{'choosedStyle':isReject}" @click="chooseReject">驳回</span>
      </div>
    </div>
    <div class="normalBlock" v-show="isPass">
      <span class="maker" :class="{'orangeColor':guidancePrice!=''}">• </span>
      <span class="blockTitle">指导价</span>
      <span class="content-divide">|</span>
      <input type="number" class="inputPrice"
             onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
             v-model="guidancePrice">
      <span class="priceUnit">元</span>
    </div>

    <div id="addressPanel" class="normalBlock" v-show="isReject" @click="isReasonShow = !isReasonShow">
      <span class="maker" :class="{'orangeColor':rejectReason.dictionaryTitle}">• </span>
      <span class="part-inputpart-row-header">原因</span>

      <span class="content-divide">|</span>
      <span class="part-inputpart-row-graytext"
            :class="rejectReason.dictionaryTitle ? 'part-inputpart-row-normaltext':'' ">{{ rejectReason.dictionaryTitle }}</span>
      <img class="part-inputpart-row-right"
           :class=" isReasonShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
           src="../../../assets/images/triangle.png">
    </div>
    <!--        <div>-->
    <!--            <div class="part-inputpart part-inputpart-dropDownList" v-if="isReasonShow">-->
    <!--                <div class="part-inputpart-dropDownList-option" :class="item.dictionaryTitle==rejectReason ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="(item,index) in reasonArr" :key="index" @click="selectAddressOption(item)">{{item.dictionaryTitle}}</div>-->
    <!--            </div>-->
    <!--        </div>-->
    <div class="displayBlock">
      <div><span class="suggestTitle">审批意见</span></div>
      <div><textarea class="textArea" v-model="approvalOpinion"></textarea></div>
    </div>
    <van-button class="saveButton_Disable"
                :class="{'saveButton_Enable': (isPass && (guidancePrice>0) ) || (isReject && rejectReason.dictionaryTitle)}"
                :disabled=" !((isPass && (guidancePrice>0) ) || (isReject && rejectReason.dictionaryTitle))"
                @click="save">保存
    </van-button>

    <van-popup v-model="isReasonShow" round position="bottom">
      <van-picker
          show-toolbar
          :columns="reasonArr"
          @cancel="isReasonShow = false"
          :default-index="defaultIndex"
          value-key="dictionaryTitle"
          @confirm="onConfirm"
      />
    </van-popup>
  </div>

</template>

<script>


import {
  NavBar, Toast,
  ImagePreview, Picker,
  Popup, Button, Image as VanImage
} from 'vant';
import BScroll from "better-scroll";
import {getStaffId, responseUtil} from '../../../libs/rongxunUtil';
import {
  colllectRoomDetail, collectRoomAgree, collectRoomRefuse, browseApprovalDetail
} from '../../../getData/getData';
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [BScroll.name]: BScroll,
    [Toast.name]: Toast,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    rxNavBar
  },
  data() {
    return {
      dataList: {},
      rejectReason: '请选择',
      refuseReason_id: '',
      reasonArr: [],
      isPass: false,
      isReject: false,
      isReasonSelect: false,
      isReasonShow: false,
      defaultIndex: '',
      imgList: [],
      approvalOpinion: '',//审批备注
      guidancePrice: '',//指导价
      id: ''
    }
  },
  methods: {

    onConfirm(value, index) {
      this.rejectReason = value
      this.defaultIndex = index
      this.isReasonShow = false
    },

    //初始化页面数据
    initData: function () {
      var that = this
      let initData = {}
      initData.staff_id = getStaffId()
      initData.id = that.id
      //console.log(initData)
      colllectRoomDetail(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          let data = response.data.data
          that.dataList = data.item
          that.reasonArr = response.data.data.refuseReasonList
          //加载图片信息
          let list = data.explorationList
          if (list) {
            // let length = data.explorationList.length - 1
            // list = data.explorationList[length].children
            // for (let i = 0; i < list.length; i++) {
            //   that.imgList.push(list[i].path)
            // }
            for(let i=0;i<data.explorationList.length;i++){
              list = data.explorationList[i].children
              if(list&&list.length>0){
                for(let j=0;j<list.length;j++){
                  that.imgList.push(list[j].path)
                }
              }
            }
            if(that.imgList.length>0){
              that.$nextTick(() => {
                if (!that.scroll) {
                  that.scroll = new BScroll(that.$refs.wrapperBox, {
                    scrollX: true,
                    eventPassthrough: 'vertical'
                  })
                }
              })
            }
          }
        })
      })
    },
    onClickLeft() {
    },
    previewImg(start) {
      ImagePreview(
          {
            images: this.imgList,
            startPosition: start,
            onClose() {
              // do something
            },
          }
      )
    },
    //返回上一页
    leftReturn() {
      this.$router.go(-1);
    },
    choosePass() {
      this.isPass = true;
      this.isReject = false;
    },
    chooseReject() {
      this.isReject = true;
      this.isPass = false;
    },
    selectAddressOption(item) {
      this.rejectReason = item.dictionaryTitle
      this.isReasonShow = false
      this.isReasonSelect = true
      this.refuseReason_id = item.id
      //console.log(this.refuseReason_id)
    },
    clickAddressShow() {
      this.isReasonShow = !this.isReasonShow;
      var panel = document.getElementById('addressPanel')
      document.addEventListener('click', e => {
        if (!panel.contains(e.target)) {
          this.isReasonShow = false
        }
      })
    },
    save() {
      let that = this
      if (that.isPass) {
        //调用审批成功接口
        that.approveSuccess()

      } else {
        //调用审批拒绝接口
        that.approveFail()
      }
      // responseUtil.alertMsg(that,'审批成功')
      // this.$router.replace('reviewOfApplyForHouse')
    },
    //审批同意接口
    approveSuccess: function () {
      var that = this
      let initData = {}
      initData.staff_id = getStaffId()
      initData.id = that.id
      initData.approvalOpinion = that.approvalOpinion
      initData.guidancePrice = that.guidancePrice
      //console.log(initData)
      collectRoomAgree(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          responseUtil.alertMsg(that,'审批成功')
          that.leftGo();
        })
      })
    },
    leftGo() {
      this.$router.go(-1)
    },
    //审批拒绝接口
    approveFail: function () {
      var that = this
      let initData = {}
      initData.staff_id = getStaffId()
      initData.id = that.id
      initData.approvalOpinion = that.approvalOpinion
      initData.refuseReason_id = that.rejectReason.id
      //console.log(initData)
      collectRoomRefuse(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          responseUtil.alertMsg(that,'审批成功')
          that.leftGo();
        })
      })
    },
  },
  created() {
    this.id = this.$route.query.id
    this.initData()
  }
}
</script>

<style lang="less">
.applyHouseCollection {
  overflow: hidden;
  width: 100%;
}

// 图片墙
.swiper_show {
  width: 9rem;
  position: relative;
  margin: 8px 13px;
  height: 80px;

  .content {
    position: absolute;
    display: flex;
    justify-content: space-around;
    left: 0px;

    .imgWrap {
      margin-right: 10px;

    }
  }

  .num {
    position: absolute;
    left: 8px;
    top: 17px;
    font-size: 10px;
    background-color: rgb(0, 0, 0);
    opacity: 0.2;
    color: rgb(255, 255, 255);
    padding: 4px 6px 2px 6px;
    display: inline-block;
    border-radius: 10px;
    font-weight: bolder;
    line-height: 10px;
  }

}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  border-radius: 8px;
}

.part-inputpart-dropDownList {
  width: 90%;
  position: absolute;
  left: 19px;
  z-index: 99;
  margin-top: -17px;

}

.part-inputpart-dropDownList-option {
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: #999999;
}

.part-inputpart-dropDownList-option-selected {
  color: #ff5d3b;
}

.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}

.part-inputpart-row-header {
  font-weight: bold;
  font-size: 15px;
  width: 18%;
}

.part-inputpart-row-graytext {
  color: #d8d8d8;
  width: 100%;
  font-size: 14px;
}

.part-inputpart-row-normaltext {
  color: black;
}

.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.part-inputpart-row {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  background-color: white;
  border-radius: 9px;
  width: 9rem;
  margin: 19px auto;
}

.content-divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}

.content-divide-small {
  font-size: 14px;
  // color: #9a9a9a;
  margin: 0 5px;
}

.selectReason {
  width: 245px;
  height: 1rem;
  line-height: 28px;
  margin-left: 20px;
  font-size: 14px;
  border-style: none;
}

.suggestTitle {
  margin-left: 14px;
  margin-top: 12px;
  float: left;
  font-size: 15px;
  font-weight: bold;
  display: block;
}

.textArea {
  resize: none;
  padding: 10px;
  margin: 38px auto 15px;
  height: 120px;
  width: 290px;
  display: block;
  font-size: 14px;
  border-radius: 8px;
  border-style: none;
  background-color: rgba(182, 177, 177, 0.11);
}

.priceUnit {
  color: rgba(153, 153, 153, 0.45);
  font-size: 14px;
  float: right;
  padding-right: 31px;
}

.inputPrice {
  color: #ff3c00;
  width: 100%;
  height: 28px;
  line-height: 28px;
  font-size: 15px;
  //font-weight: bold;
  border: none;
}

.divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}

.passText {
  flex: 1;
  padding-right: 7px;
}

.rejectText {
  padding-left: 7px;
  padding-right: 33px;

}

.rightText {
  margin-left: auto;
  font-size: 14px;
  color: #999999;
}

.maker {
  color: #999999;
  padding-left: 14px;
  padding-right: 8px;
  font-size: 28px;
}

.wait-approve {
  width: 46px;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  text-align: center;
  padding-top: 2px;
  color: white;
  border-radius: 8px 0;
  background: linear-gradient(to right, #ffb96f, #ff6d44);
}

.orangeColor {
  color: #ff3c00;
}

.choosedStyle {
  color: #ff3c00;
  font-weight: bold;
}

.normalFont {
  font-size: 14px;
  font-weight: normal;
  color: #7d7b7b;
}

.blockTitle {
  overflow: hidden;
  font-size: 15px;
  font-weight: bold;
  width: 27%;
}

.boldFont {
  font-size: 14px;
  font-weight: bold;
}

.descTitle {
  height: 25px;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
}

.descText {
  height: auto;
  padding-left: 20px;
  padding-bottom: 14px;
  padding-right: 20px;
  line-height: 23px;
}

.houseInfo {
  padding-top: 14px;
  padding-bottom: 14px;
  width: 9.2rem;
  padding-left: 20px;
}

.applyTime {
  font-size: 12px;
  text-align: right;
  color: #7d7b7b;
  padding-right: 15px;
  margin-top: 16px;
  margin-bottom: 6px;
}

.houseAddress {
  font-size: 16px;
  font-weight: bold;
  height: auto;
  padding-top: 8px;
  padding-left: 15px;
}

.keyAddress {
  font-size: 14px;
  height: auto;
  padding-top: 7px;
  padding-bottom: 17px;
  padding-left: 15px;
  color: #7d7b7b;

}

.headerBlock {
  background-color: white;
  border-radius: 6px;
  width: 9.2rem;
  height: available;
  margin: 0px auto 19px;
}

.nav-bar {
  background-color: #f8f8f8;
  border: none;
  font-weight: bold;
}

.saveButton_Disable {

  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 9rem;
  height: 1.22rem;
  border-radius: 8px;
  margin: 30px 19px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}

.saveButton_Enable {
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;

}

.middle {
  overflow: hidden;
  height: auto;
}

.displayBlock {
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width: 9rem;
  height: auto;
  margin: 19px auto;
}

.normalBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width: 9rem;
  line-height: 1rem;
  height: 50px;
  margin: 19px auto;
  align-items: center;
}


</style>
